<template>
  <div>
    <vx-card
      ><h3 class="mb-5 text-info">{{ $t("Nueva regla") }}</h3>
      <div class="grid grid-cols-2 gap-5">
        <div class="w-full">
          <label for="rule_name">{{ $t("Nombre de la regla") }}:</label>
          <vs-input v-model="form.name" id="rule_name" class="w-full" />
          <p
            v-if="errors.name"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.name }}
          </p>
        </div>
        <div>
          <label for="quantity_customers"
            >{{ $t("Cantidad por usuario") }}:</label
          >
          <vs-input
            type="number"
            v-model.number="form.quantity_customers"
            id="quantity_customers"
            class="w-full"
            min="0"
          />
          <p
            v-if="errors.quantity_customers"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.quantity_customers }}
          </p>
        </div>

        <div>
          <label for="quantity_shopkeepers"
            >{{ $t("Cantidad de promociones") }}:</label
          >
          <vs-input
            type="number"
            v-model.number="form.quantity_shopkeepers"
            id="quantity_shopkeepers"
            class="w-full"
            min="0"
          />
          <p
            v-if="errors.quantity_shopkeepers"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.quantity_shopkeepers }}
          </p>
        </div>
        <div class="hidden">
          <label for="val_customer"
            >{{ $t("Valor máximo por consumidor") }}:</label
          >
          <vs-input
            type="number"
            v-model.number="form.val_customer"
            id="val_customer"
            class="w-full"
            min="0"
          />
          <p
            v-if="errors.val_customer"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.val_customer }}
          </p>
        </div>
        <div>
          <label for="type_promotions_id">{{ $t("Tipo de promoción") }}:</label>
          <VSelect
            id="type_promotions_id"
            :options="promotionTypes"
            v-model="form.type_promotions_id"
            class="w-full"
          >
          </VSelect>
          <p
            v-if="errors.type_promotions_id"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.type_promotions_id }}
          </p>
        </div>
        <!-- PROMOCION DESCUENTOS -->
        <div
          v-if="
            form.type_promotions_id &&
            form.type_promotions_id.label === 'Descuentos'
          "
        >
          <label for="percent_discount"
            >{{ $t("Porcentaje de descuento") }} (%):</label
          >
          <vs-input
            type="number"
            v-model.number="form.percent_discount"
            id="percent_discount"
            class="w-full"
            min="0"
          />
          <p
            v-if="errors.percent_discount"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.percent_discount }}
          </p>
        </div>
        <!-- PROMOCION COMBO -->
        <div
          v-if="
            form.type_promotions_id && form.type_promotions_id.label === 'Combo'
          "
        >
          <label for="percent_discount"
            >{{ $t("Porcentaje de descuento") }} (%):</label
          >
          <vs-input
            type="number"
            v-model.number="form.percent_discount"
            id="percent_discount"
            class="w-full"
            min="0"
          />
          <p
            v-if="errors.percent_discount"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.percent_discount }}
          </p>
        </div>
        <div
          v-if="
            form.type_promotions_id && form.type_promotions_id.label === 'Combo'
          "
        >
          <label for="promotion-type">{{ $t("Tipo de activación") }}:</label>
          <VSelect
            id="promotion-type"
            :options="activatetypes"
            :reduce="(product) => product.id"
            v-model="form.activate"
            class="w-full"
          >
          </VSelect>
          <p
            v-if="errors.activate"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.activate }}
          </p>
        </div>
        <div
          v-if="
            form.type_promotions_id &&
            form.type_promotions_id.label === 'Combo' &&
            form.activate === 'value'
          "
        >
          <label for="combo_value">{{ $t("Valor combo") }}:</label>
          <vs-input
            type="number"
            v-model.number="form.combo_value"
            id="combo_value"
            class="w-full"
            min="0"
          />
          <p
            v-if="errors.combo_value"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.combo_value }}
          </p>
        </div>

        <!-- PROMOCION BONIFICACION -->
        <div
          v-if="
            form.type_promotions_id &&
            form.type_promotions_id.label === 'Bonificacion'
          "
        >
          <label for="form_reward"
            >{{ $t("Producto de bonificar") }} (SKU):</label
          >
          <vs-input
            type="text"
            v-model="form.reward"
            id="form_reward"
            class="w-full"
          />
          <p
            v-if="errors.reward"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.reward }}
          </p>
        </div>
        <div
          v-if="
            form.type_promotions_id &&
            form.type_promotions_id.label === 'Bonificacion'
          "
        >
          <label for="reward_quant">{{ $t("Cantidad a bonificar") }}:</label>
          <vs-input
            type="number"
            v-model.number="form.reward_quant"
            id="reward_quant"
            class="w-full"
            min="0"
          />
          <p
            v-if="errors.reward_quant"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.reward_quant }}
          </p>
        </div>
        <div
          v-if="
            form.type_promotions_id &&
            form.type_promotions_id.label === 'Bonificacion'
          "
        >
          <label for="form_activate">{{ $t("Tipo de activación") }}:</label>
          <VSelect
            id="form_activate"
            :options="activatetypes"
            :reduce="(product) => product.id"
            v-model="form.activate"
            class="w-full"
          >
          </VSelect>
          <p
            v-if="errors.activate"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.activate }}
          </p>
        </div>
        <div
          v-if="
            form.type_promotions_id &&
            form.type_promotions_id.label === 'Bonificacion' &&
            form.activate === 'value'
          "
        >
          <label for="activate_val">{{ $t("Valor para activación") }}:</label>
          <vs-input
            type="number"
            v-model.number="form.activate_val"
            id="activate_val"
            class="w-full"
            min="0"
          />
          <p
            v-if="errors.activate_val"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.activate_val }}
          </p>
        </div>
        <div
          v-if="
            form.type_promotions_id &&
            form.type_promotions_id.label === 'Bonificacion' &&
            form.activate === 'quantity'
          "
        >
          <label for="activate_quant"
            >{{ $t("Cantidad para activación") }}:</label
          >
          <vs-input
            type="number"
            v-model.number="form.activate_quant"
            id="activate_quant"
            class="w-full"
            min="0"
          />
          <p
            v-if="errors.activate_quant"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.activate_quant }}
          </p>
        </div>
        <!-- PROMOTION ESCALONADA -->
        <div
          v-if="
            form.type_promotions_id &&
            form.type_promotions_id.label === 'Escalonada'
          "
        >
          <label for="form_activate">{{ $t("Tipo de activación") }}:</label>
          <VSelect
            id="form_activate"
            :options="activatetypes"
            :reduce="(product) => product.id"
            v-model="form.activate"
            class="w-full"
          >
          </VSelect>
          <p
            v-if="errors.activate"
            class="text-sm font-light span-text-validation-danger"
          >
            {{ errors.activate }}
          </p>
        </div>
        <div
          v-if="
            form.type_promotions_id &&
            form.type_promotions_id.label === 'Escalonada' &&
            form.activate
          "
          class="my-2 col-span-full px-5 pt-6 pb-8 rounded-lg shadow-sm"
        >
          <div
            v-for="(item, index) in form.activate_list"
            :key="index"
            class="flex items-end gap-4"
          >
            <div>
              <label
                >{{
                  form.activate === "value" ? $t("Valor") : $t("Cantidad")
                }}:</label
              >
              <vs-input
                type="number"
                v-model.number="item[form.activate]"
                class="w-full"
                min="0"
              />
            </div>
            <div>
              <label for="percent_discount">{{ $t("Descuento") }} (%):</label>
              <vs-input
                type="number"
                v-model.number="item['percent_discount']"
                id="percent_discount"
                class="w-full"
                min="0"
              />
            </div>
            <vs-button
              v-if="form.activate_list.length > 1"
              class="flex-none"
              @click.native="form.activate_list.splice(index, 1)"
              :disabled="loading"
            >
              -
            </vs-button>
            <vs-button
              v-if="index === form.activate_list.length - 1"
              class="flex-none"
              @click.native="form.activate_list.push({})"
              :disabled="loading"
            >
              +
            </vs-button>
          </div>
        </div>
      </div>

      <!-- Action buttons -->
      <div class="mt-5 flex justify-end gap-5">
        <vs-button
          :color="'#FFF'"
          :text-color="'#666'"
          class="rounded-full"
          @click.native="$router.go(-1)"
        >
          {{ $t("Cancelar") }}
        </vs-button>

        <vs-button
          class="rounded-full flex items-center"
          @click.native="addRule"
          :disabled="loading"
        >
          <div v-if="loading" class="h-full grid place-items-center gap-2">
            <feather-icon
              :icon="'LoaderIcon'"
              class="text-white w-5 h-2 m-0 p-0 animate-spin"
            />
          </div>
          <p v-else>{{ $t("Agregar") }}</p>
        </vs-button>
      </div></vx-card
    >
  </div>
</template>
<script>
import VSelect from "vue-select";
import http from "@/http/promotions-http-client.js";
import rulesFormValidation from "@/assets/utils/rulesFormValidation.js";
export default {
  name: "AddRulePage",
  components: {
    VSelect,
  },
  data() {
    return {
      form: {
        name: "",
        quantity_customers: "",
        quantity_shopkeepers: "",
        status: false,
        type_promotions_id: "",
        percent_discount: "",
        activate: "",
        activate_quant: "",
        activate_val: "",
        reward: "",
        reward_quant: "",
        combo_value: "",
        activate_list: [],
      },
      errors: {
        name: "",
        quantity_customers: "",
        quantity_shopkeepers: "",
        percent_discount: "",
        activate: "",
        activate_quant: "",
        activate_val: "",
        reward: "",
        reward_quant: "",
        combo_value: "",
      },
      loading: false,
      promotionTypes: [],
      activatetypes: [
        { label: "Cantidad", id: "quantity" },
        { label: "Valor", id: "value" },
      ],
    };
  },
  async mounted() {
    await this.getPromotionTypes();
    this.$store.dispatch("updatePageTitle", {
      title: this.$t("Reglas"),
      subtitle: this.$t("Agrega una regla"),
    });
  },
  watch: {
    "form.reward"(value) {
      if (!value) return;
      if (value.length < 5) return;
      this.getProductBySKU(value);
    },
    "form.type_promotions_id.label"(value) {
      if (!value) return;
      if (this.form.activate_list.length > 0) {
        this.form.activate_list = [];
      }
    },
    "form.activate"(value) {
      if (!value) {
        this.form.activate_list = [];
        return;
      }
      if (this.form.type_promotions_id.label === "Escalonada") {
        this.form.activate_list = [{}];
      }
    },
  },
  methods: {
    async addRule() {
      if (this.isInvalidForm()) return;
      const { form } = this;
      const data = {
        ...form,
        type_promotions_id:
          this.promotionTypes.find(
            (item) => item.id === form.type_promotions_id.id
          ).id || "",
      };

      this.loading = true;
      const response = await http
        .post("api/RulesPromotions", data)
        .catch((this.loading = false));
      this.loading = false;
      if (!response || response.status !== 201) return;
      this.$router.go(-1);
    },
    isInvalidForm() {
      this.cleanErrors();
      const { form } = this;
      const result = rulesFormValidation(form, this);
      if (JSON.stringify(result) === "{}") return false;
      this.errors = result;
      return true;
    },
    cleanErrors() {
      for (const error in this.errors) {
        this.errors[error] = "";
      }
    },
    async getPromotionTypes() {
      this.loading = true;
      const response = await http.get("api/TypesPromotionsSh");
      this.loading = false;
      if (response.status !== 200) return;
      this.promotionTypes = response.data.map((option) => ({
        id: option.key,
        label: option.name,
      }));
    },
    async getProductBySKU(sku) {
      this.cleanErrors();
      if (!sku) return;

      const response = await http.post("api/ProductsSakura", {
        sku,
      });
      if (!response || response.status !== 200) {
        this.errors.reward = this.$t("El producto no existe");
      }
    },
  },
};
</script>
