export default (form, instance) => {
  const errors = {};

  for (const input in form) {
    if (["name", "type_promotions_id"].includes(input) && form[input] === "") {
      errors[input] = instance.$t("Campo invalido");
    }

    if (
      (["quantity_customers", "quantity_shopkeepers"].includes(input) &&
        !form[input]) ||
      parseInt(form[input]) < 0
    ) {
      errors[input] = instance.$t("Campo invalido");
    }

    // Revisa los campos si es Descuentos

    if (
      input === "percent_discount" &&
      form["type_promotions_id"].label === "Descuentos" &&
      (!form[input] || parseInt(form[input]) > 100)
    ) {
      errors[input] = instance.$t("Campo invalido");
    }
    // PROMOCION BONIFICACION
    // Revisa los campos si es una Bonificacion

    if (
      ["activate", "reward", "reward_quant"].includes(input) &&
      form["type_promotions_id"].label === "Bonificacion" &&
      !form[input]
    ) {
      errors[input] = instance.$t("Campo invalido");
    }
    // Revisa el valor de activacion
    if (
      form["type_promotions_id"].label === "Bonificacion" &&
      input === "activate_val" &&
      form["activate"] === "value" &&
      !form[input]
    ) {
      errors[input] = instance.$t("Campo invalido");
    }

    if (
      form["type_promotions_id"].label === "Bonificacion" &&
      input === "activate_quant" &&
      form["activate"] === "quantity" &&
      !form[input]
    ) {
      errors[input] = instance.$t("Campo invalido");
    }

    // PROMOCION COMBO
    // Revisa el valor de activacion
    if (
      form["type_promotions_id"].label === "Combo" &&
      ["activate"].includes(input) &&
      !form[input]
    ) {
      errors[input] = instance.$t("Campo invalido");
    }
    if (
      input === "percent_discount" &&
      form["type_promotions_id"].label === "Combo" &&
      (!form[input] || parseInt(form[input]) > 100)
    ) {
      errors[input] = instance.$t("Campo invalido");
    }
    if (
      form["type_promotions_id"].label === "Combo" &&
      input === "combo_value" &&
      form["activate"] === "value" &&
      !form[input]
    ) {
      errors[input] = instance.$t("Campo invalido");
    }

    // PROMOCION ESCALONADA
    // Revisa los campos si es una Escalonada
    if (
      form["type_promotions_id"] &&
      form["type_promotions_id"].label === "Escalonada" &&
      ["activate"].includes(input) &&
      !form[input]
    ) {
      errors[input] = instance.$t("Campo invalido");
    }
  }

  return errors;
};
